.container {
    padding: 20px;
    color: white;
}

.quarterButtons {
    margin-bottom: 20px;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.tableContainer {
    margin-top: 20px;
}