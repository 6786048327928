@import './mixins.scss';
@import './variables.scss';

.grid {
    max-width: 115rem;
    margin-right: auto;
    margin-left: auto;

    @include bp(l-hd) {
        max-width: 120rem;
    }
}

.grid {
    width: 100%;
    padding-right: 1.875rem;
    padding-left: 1.875rem;

    @include bp(xxxl) {
        padding-right: clamp(1.875rem, 4vw, 10rem);
        padding-left: clamp(1.875rem, 4vw, 10rem);
    }
}
