/* src/components/Login.scss */
.loginContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    h2 {
        color: white;
        margin-top: 0;
    }

    &Error {
        color: red;
        margin-top: 10px;
        position: absolute;
        bottom: -50px;
    }

    &Btn {
        width: 70% !important;
        margin: 10px auto 0;
    }
}

.form {
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    &Input {
        width: 50%;
        height: 44px;
        padding: 0 16px;
        border: 1px solid black;
        border-radius: 4px;
        justify-self: center;
    }
}
