@import './assets/styles/mixins';

.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    background: black url('./assets/images/q3logo.jpg') center center repeat fixed;

    @include bp-only(mobile) {
        background-size: contain;
    }
}