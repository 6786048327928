@import '../../assets/styles/mixins';

.modal {
    position: relative;
    width: 100%;
    height: 100%;
    color: white;
    z-index: 2;

    &Container {
        position: absolute;
        top: 10%;
        left: 50%;
        transform: translateX(-50%);
        width: 90%;
        height: auto;
        border: 1px solid  rgba(255, 255, 255, 0.8);;
        background-color: rgba(0, 0, 0, 1);
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 30px 10px;

        @include bp-only(mobile) {
            overflow: hidden;
            overflow-y: auto;
            max-height: 75%;
        }

        @include bp(l) {
            width: auto;
            min-width: 450px;
            height: auto;
            padding: 55px;
        }

        &Backdrop {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.8);
            z-index: 2;
        }
    }

    &Content {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }

    &Close {
        font-size: 1rem;
        font-weight: bold;
        position: absolute;
        top: 12px;
        right: 12px;
        cursor: pointer;
        width: 20px;
        height: 20px;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;

        @include bp(l) {
            top: -45px;
            right: -45px;
        }
    }
}