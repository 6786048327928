@import '../../assets/styles/mixins';

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0 19px;

    @include bp-only(mobile) {
        position: relative;
    }

    @include bp(l) {
        padding: 0;
        width: 50vw;
    }

    h2 {
        font-size: 3rem;
        display: flex;
        justify-content: center;
        text-align: center;
        width: 100%;
    }

    &Paragraph {
        font-size: 20px;
        line-height: 38px;
    }

    table {
        width: 100%;
        border-collapse: collapse;
        border: 1px solid #ddd;

        tbody {
            tr {
                height: 40px;

                td {
                    @include transition();

                    min-width: 250px;
                    padding: 8px;
                    background: transparent;

                    &:hover {
                        background: rgba(#5f5f5f, 0.5);
                    }
                }
            }
        }
    }

    &ImagePreview {
        position: absolute;
        top: 0;
        left: 0;
        width: 335px;
        height: 190px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        border: 2px solid black;
        pointer-events: none;
        z-index: 1000;
        display: none;

        @include bp(l) {
            width: 500px;
            height: 300px;
        }
    }

    &Table {
        @include bp-only(mobile) {
            overflow: hidden;
            overflow-x: scroll;
            -webkit-overflow-scrolling: touch;
            grid-auto-flow: column;
            grid-auto-columns: 42%;
            min-height: 100%;
            justify-content: flex-start;
            width: 90vw;
            margin: 0 auto;
        }

        @include bp(l) {
            margin: 0 auto;
        }

        &Header {
            text-align: center;
            font-size: 1.5rem;
            background: rgba(#5f5f5f, 0.7);
        }
    }

    &RowCollapsed {
        background: #000000;
        text-align: center;
        font-size: 1.2rem;
        height: 50px;
    }

    &ItdmList {
        text-align: center;
    }

    &Info {
        font-size: 11px;
        text-align: right;
        width: 100%;
        margin: 25px 0;

        @include bp(l) {
            font-size: 16px;
            text-align: center;
        }
    }
}

.languageToggle {
    display: flex;
    width: 80%;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    margin: 30px auto;

    &Button {
        padding: 8px 16px;
        background-color: #333;
        border: none;
        color: white;
        cursor: pointer;
        border-radius: 5px;
        transition: background-color 0.3s;

        &.active {
            background-color: #555;
        }

        &:hover {
            background-color: #444;
        }
    }
}