// Navbar.module.scss
.menu {
    background-color: #333;
    padding: 1rem;
    ul {
        list-style: none;
        display: flex;
        gap: 1rem;
        justify-content: center;
        margin: 0;
        padding: 0;
    }
    li {
        a {
            color: white;
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
    }
}
