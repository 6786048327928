.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;

    &Form {
        width: 100%;
    }

    &Wrapper {
        margin: 0 0 20px 0;
        display: flex;
        justify-content: space-between;
    }

    &Label {
        font-size: 25px;
        margin-right: 7px;
        flex: 1;
        display: flex;
        align-items: center;
    }

    &Select {
        width: 100%;
        height: 44px;
        flex: 1;
    }

    &ChoosePlayers {
        display: flex;
        margin: 18px 0;
        justify-content: space-between;
    }

    &Buttons {
        display: flex;
        justify-content: center;
    }
}