@import '../../../assets/styles/mixins';

.container {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &Title {
        font-size: 2rem;
    }

    &List {
        list-style: none;
        width: 90%;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 25px;
        padding: 0 10px;

        @include bp(l) {
            padding: 0 45px;
            gap: 50px 35px;
            max-width: 1200px;
            grid-template-columns: repeat(2, 1fr);
        }

        &Item {
            display: flex;
            flex-direction: column;

            @include bp-only(desktop) {
                min-width: 487px;
            }

            &Title {
                display: flex;
                justify-content: space-between;
            }

            &Date {
                display: flex;
                font-size: 1.2rem;

                &Title {
                    font-weight: bold;
                    margin-right: 20px;
                }
            }

            &Buttons {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 5px;

                &Btn {
                    height: 27px;
                    width: 70px;
                }
            }
        }
    }

    &Match {
        display: flex;
        width: 100%;
        justify-content: space-between;

        &Score {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            margin: 0;

            @include bp(l) {
                margin: 0 25px;
            }

            &Divider {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: flex-start;
                font-size: 1.5rem;
                padding: 0 15px;

                @include bp(l) {
                    font-size: 3rem;
                }
            }
        }

        &Screens {
            padding: 0 0 25px;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 20px;
            flex: 1;
        }

        &ScoreScreenContainer {
            display: flex;
            flex-direction: column;
        }
    }

    &Team {
        display: flex;
        flex-direction: column;
        flex: 1;

        &Name {
            display: flex;
            align-items: center;
            font-weight: bold;
            font-size: 1.2rem;
            border-bottom: 2px solid rgba(255, 255, 255, 0.5);
            padding-bottom: 8px;

            &Home {
                justify-content: flex-start;
            }

            &Away {
                justify-content: flex-end;
                text-align: end;
            }
        }

        &Score {
            font-size: 1.5rem;

            @include bp(l) {
                font-size: 3rem;
            }
        }

        &Players {
            display: flex;
            flex-direction: column;

            p {
                padding: 0
            }

            &Home {
                align-items: flex-start;
                text-align: start;
            }

            &Away {
                align-items: flex-end;
                text-align: end;
            }
        }
    }
}

.filterButtons {
    display: flex;
    width: 80%;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;

    .filterButton {
        padding: 8px 16px;
        background-color: #333;
        border: none;
        color: white;
        cursor: pointer;
        border-radius: 5px;
        transition: background-color 0.3s;

        &.active {
            background-color: #555;
        }

        &:hover {
            background-color: #444;
        }
    }
}
