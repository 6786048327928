@import './variables.scss';

@mixin bp($class) {
    @if $class == xs {
        @media (min-width: $screen-xs) {
            @content;
        }
    } @else if $class == s {
        @media (min-width: $screen-s) {
            @content;
        }
    } @else if $class == m {
        @media (min-width: $screen-m) {
            @content;
        }
    } @else if $class == l {
        @media (min-width: $screen-l) {
            @content;
        }
    } @else if $class == xl {
        @media (min-width: $screen-xl) {
            @content;
        }
    } @else if $class == xxl {
        @media (min-width: $screen-xxl) {
            @content;
        }
    } @else if $class == xxxl {
        @media (min-width: $screen-xxxl) {
            @content;
        }
    } @else if $class == hd {
        @media (min-width: $screen-hd) {
            @content;
        }
    } @else if $class == l-hd {
        @media (min-width: $screen-l-hd) {
            @content;
        }
    } @else {
        @warn "Breakpoint mixin supports: xs, s, md, l, xl, xxl, xxxl, hd, l-hd";
    }
}


@mixin bp-only($class) {
    @if $class == mobile {
        @media (max-width: $screen-s-max) {
             /* max 767px */
            @content;
        }
    } @else if $class == tablet {
        @media (min-width: $screen-m) and (max-width: $screen-l-max) {
         /* from 768px to 1365px */
            @content;
        }
    } @else if $class == desktop {
        @media (min-width: $screen-xl) {
            /* min 1366px */
            @content;
        }
    } @else {
        @warn "Breakpoint mixin supports: mobile, tablet, desktop";
    }
}

@mixin transition($type: '') {
    @if $type != '' {
        transition: $type 0.3s ease-in-out;
    } @else {
        transition: all 0.3s ease-in-out;
    }
}