@import '../../../assets/styles/mixins';

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    font-size: 22px;
    width: 90%;

    @include bp(l) {
        flex-direction: row;
    }

    div {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @include bp(l) {
            width: 33%;
        }
    }
}

.teamCard {
    width: 100%;

    p {
        margin: 0;
    }

    h4 {
        margin: 25px 0 0;
    }

    h2 {
        margin: 35px 0 15px;
    }
}